<template>
  <b-navbar-nav class="nav align-items-center ml-auto">
    <b-nav-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLanguage(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="24px"
        width="40px"
        :alt="localeObj.locale"
      />
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
import { BImg, BNavbarNav, BNavItem } from "bootstrap-vue";
import { localeChanged } from "vee-validate";

export default {
  components: {
    BImg,
    BNavbarNav,
    BNavItem,
  },
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  methods: {
    changeLanguage(locale) {
      this.$i18n.locale = locale;
      localeChanged();
      localStorage.setItem("locale", locale);
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "English",
      },
      {
        locale: "km",
        img: require("@/assets/images/flags/km.png"),
        name: "ខ្មែរ",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
};
</script>

<style>
</style>
