export default [
  {
    title: 'breadcrumb.configuration',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'breadcrumb.vehicleConfiguration',
        children: [
          {
            title: 'breadcrumb.location',
            route: 'list-location',
            resource: 'location',
            action: 'read'
          },
          {
            title: 'breadcrumb.zone',
            route: 'list-zone',
            resource: 'zone',
            action: 'read'
          },
          {
            title: 'breadcrumb.brand',
            route: 'list-vehicle-brand',
            icon: '',
            resource: 'vehicle-brand',
            action: 'read'
          },
          {
            title: 'breadcrumb.model',
            route: 'list-vehicle-model',
            icon: '',
            resource: 'vehicle-model',
            action: 'read'
          },
          {
            title: 'breadcrumb.color',
            route: 'list-vehicle-color',
            icon: '',
            resource: 'vehicle-color',
            action: 'read'
          },
          {
            title: 'breadcrumb.fuelType',
            route: 'list-vehicle-fuel-type',
            icon: '',
            resource: 'vehicle-fuel-type',
            action: 'read'
          },
        ],
      },
      {
        title: 'breadcrumb.setting',
        route: 'list-setting',
        resource: 'setting',
        action: 'read'
      },
      {
        title: 'breadcrumb.guideline',
        route: 'list-guideline',
        resource: 'guideline',
        action: 'read'
      },
    ],
  },
];